$text-color: #2c2c2c;
$secondary-text-color: #303030;
$external-link-color: #307DF6;
$background-color: rgb(254, 254, 254);
$secondary-background-color: rgb(245, 245, 245);
$footer-background-color: #19191A;
$footer-text: #ececec;

body {
  background-color: $footer-background-color;
}

a:hover, a:visited, a:link, a:active {
  text-decoration: none;
  color: inherit;
}

.App {
  text-align: center;
  background-color: $background-color;
}

.Divider {
  margin: 0;
  padding: 0;
  border: 0;
  height: 2px;
  color: #fff;
	background: linear-gradient(-45deg, #000000, #747474, #ffffff, #747474);
	background-size: 400% 400%;
	-webkit-animation: Gradient 5s ease infinite;
	-moz-animation: Gradient 5s ease infinite;
	animation: Gradient 5s ease infinite;
}

.LargeTitle {
  font-family: Merriweather, Arial;
  font-weight: 700;
  font-size: 40px;
  margin-top: 30px;
  margin-bottom: 70px;
}

.Block {
  color: $text-color;
  text-align: left;
  font-family: Inconsolata, arial;
  font-size: 18px;
  display: block;
  background-color: $background-color;
}

.BlockContent {
  width: 70%;
  max-width: 800px;
  margin: auto;
  padding-top: 80px;
  padding-bottom: 100px;
  .Tag {
    background-color: $footer-background-color;
  }
}

.withSecondaryBackground {
  background-color: $secondary-background-color;
}

.HeaderContent {
  min-height: 400px;
  p {
    margin: 0;
  }
  .Bio {
    float: left;
    max-width: 40%;
    
  }
  img {
    float: right;
    width: 50%;
  }
}

.ForwardNav{
  margin-top: 20px;
  transition: all .6s cubic-bezier(0,.5,.51,1);
  img {
    position: absolute;
    max-width: 12px;
    margin-left: 85px;
    margin-top: 1px;
    height: 19px;
    width: 12px;
  }
  h3 {
  color: $secondary-text-color;
  font-family: Inconsolata;
  font-size: 19px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  }
  &:hover {
    transform: translate(7px);
  }
}

.Tag {
  height: 27px;
  border-radius: 20px;
  padding: 3px 26px 3px 24px;
  display: inline-block;
  margin-bottom: 50px;

  p {
    margin-top: 4px;
    padding: 0px;
    font-family: Arial;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    color: #ffffff;
  }
}

.ProjectTitle {
  font-size: 28px;
  font-weight: 700;
}

.ProjectLink {
  margin-top: -5px;
  font-size: 18px;
  word-wrap: break-word;
  color: $external-link-color;
  font-weight: bold;
}
@media screen and (max-width: 340px) {
  .ProjectLink {
    font-size: 16px;
  }
}

.narrowText {
  line-height: 1.4rem;
}

.Introduction {
  margin-top: -20px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.ProfilePhoto {
  width: 170px;
  height: 170px;
}

.ContentTitle {
  font-family: Merriweather, Arial;
  font-weight: 700;
  font-size: 30px;
  margin-bottom: 70px;
}

.LongBio {
  margin-top: 50px;
}

.SecondarySection {
  margin-top: -25px;
}

.ImageGallery {
  margin-top: 50px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.ImageInGallery {
  position: relative;
  width: 31%;
  padding-top: 31%;
}

.ImageInContainer {
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 650px) {
  .ImageInGallery {
    width: 100%;
    padding-top: 100%;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 700px) {
  .HeaderContent {
    height: 700px;
    img {
      width: 100%;
    }
    .Bio {
      margin-top: 30px;
      max-width: 100%;
    }
  }
}

@-webkit-keyframes Gradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

@-moz-keyframes Gradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

@keyframes Gradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}
