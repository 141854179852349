$text-color: #2c2c2c;
$background-color: rgb(254, 254, 254);
$secondary-text-color: #303030;
$secondary-background-color: rgb(245, 245, 245);
$footer-background-color: #19191A;
$footer-text: #ececec;

.HeaderBlock {
  color: $text-color;
  background-color: $background-color;
  font-size: 18px;
  display: block;
  border-bottom: solid 2px $secondary-background-color;
}

.HeaderBlockContent {
  width: 70%;
  font-family: Inconsolata;
  max-width: 800px;
  margin: auto;
  padding-top: 30px;
  padding-bottom: 20px;
}

.BackNav{
  position: absolute;
  transition: all .6s cubic-bezier(0,.5,.51,1);
  img {
    float: left;
    height: 19px;
    width: 12px;
  }
  h3 {
    float: right;
  color: $secondary-text-color;
  font-family: Inconsolata;
  font-size: 19px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-top: 0px;
  margin-left: 12px;
  }
  &:hover {
    transform:translate(-7px);
  }
}

.PageTitle {
  margin-top: -10px;
  font-weight: 700;
}

.PageLinkContainer {
  font-size: 20px;
  font-weight: 700;
  color: $secondary-text-color;

  p {
    transition: all .6s cubic-bezier(0,.5,.51,1);
    cursor: pointer;
  }

  &:hover {
    p {
      transform: translateX(-10px);
    }
  }
}

.NavHeader {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}