body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  background-color: #19191A; }

a:hover, a:visited, a:link, a:active {
  text-decoration: none;
  color: inherit; }

.Page_App__xsJFl {
  text-align: center;
  background-color: #fefefe; }

.Page_Divider__3XDyI {
  margin: 0;
  padding: 0;
  border: 0;
  height: 2px;
  color: #fff;
  background: linear-gradient(-45deg, #000000, #747474, #ffffff, #747474);
  background-size: 400% 400%;
  -webkit-animation: Page_Gradient__2FKp1 5s ease infinite;
  animation: Page_Gradient__2FKp1 5s ease infinite; }

.Page_LargeTitle__2Zb4S {
  font-family: Merriweather, Arial;
  font-weight: 700;
  font-size: 40px;
  margin-top: 30px;
  margin-bottom: 70px; }

.Page_Block__14FDI {
  color: #2c2c2c;
  text-align: left;
  font-family: Inconsolata, arial;
  font-size: 18px;
  display: block;
  background-color: #fefefe; }

.Page_BlockContent__2r_pz {
  width: 70%;
  max-width: 800px;
  margin: auto;
  padding-top: 80px;
  padding-bottom: 100px; }
  .Page_BlockContent__2r_pz .Page_Tag__6ErPL {
    background-color: #19191A; }

.Page_withSecondaryBackground__2D6lM {
  background-color: whitesmoke; }

.Page_HeaderContent__3Pm_1 {
  min-height: 400px; }
  .Page_HeaderContent__3Pm_1 p {
    margin: 0; }
  .Page_HeaderContent__3Pm_1 .Page_Bio__2TgV6 {
    float: left;
    max-width: 40%; }
  .Page_HeaderContent__3Pm_1 img {
    float: right;
    width: 50%; }

.Page_ForwardNav__1AHZF {
  margin-top: 20px;
  -webkit-transition: all 0.6s cubic-bezier(0, 0.5, 0.51, 1);
  transition: all 0.6s cubic-bezier(0, 0.5, 0.51, 1); }
  .Page_ForwardNav__1AHZF img {
    position: absolute;
    max-width: 12px;
    margin-left: 85px;
    margin-top: 1px;
    height: 19px;
    width: 12px; }
  .Page_ForwardNav__1AHZF h3 {
    color: #303030;
    font-family: Inconsolata;
    font-size: 19px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal; }
  .Page_ForwardNav__1AHZF:hover {
    -webkit-transform: translate(7px);
            transform: translate(7px); }

.Page_Tag__6ErPL {
  height: 27px;
  border-radius: 20px;
  padding: 3px 26px 3px 24px;
  display: inline-block;
  margin-bottom: 50px; }
  .Page_Tag__6ErPL p {
    margin-top: 4px;
    padding: 0px;
    font-family: Arial;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    color: #ffffff; }

.Page_ProjectTitle__3Vefm {
  font-size: 28px;
  font-weight: 700; }

.Page_ProjectLink__1S6xr {
  margin-top: -5px;
  font-size: 18px;
  word-wrap: break-word;
  color: #307DF6;
  font-weight: bold; }

@media screen and (max-width: 340px) {
  .Page_ProjectLink__1S6xr {
    font-size: 16px; } }

.Page_narrowText__1QNCF {
  line-height: 1.4rem; }

.Page_Introduction__3ey3s {
  margin-top: -20px;
  width: 100%;
  display: flex;
  justify-content: center; }

.Page_ProfilePhoto__1t_fb {
  width: 170px;
  height: 170px; }

.Page_ContentTitle__3VoiA {
  font-family: Merriweather, Arial;
  font-weight: 700;
  font-size: 30px;
  margin-bottom: 70px; }

.Page_LongBio__Dq3BT {
  margin-top: 50px; }

.Page_SecondarySection__37jI6 {
  margin-top: -25px; }

.Page_ImageGallery__29PzA {
  margin-top: 50px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.Page_ImageInGallery__YAg7G {
  position: relative;
  width: 31%;
  padding-top: 31%; }

.Page_ImageInContainer__3A5u_ {
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%; }

@media only screen and (max-width: 650px) {
  .Page_ImageInGallery__YAg7G {
    width: 100%;
    padding-top: 100%;
    margin-bottom: 30px; } }

@media only screen and (max-width: 700px) {
  .Page_HeaderContent__3Pm_1 {
    height: 700px; }
    .Page_HeaderContent__3Pm_1 img {
      width: 100%; }
    .Page_HeaderContent__3Pm_1 .Page_Bio__2TgV6 {
      margin-top: 30px;
      max-width: 100%; } }

@-webkit-keyframes Page_Gradient__2FKp1 {
  0% {
    background-position: 0% 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0% 50%; } }

@keyframes Page_Gradient__2FKp1 {
  0% {
    background-position: 0% 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0% 50%; } }

.Footer_a__HPR-X:hover, a:visited, a:link, a:active {
  text-decoration: none; }

.Footer_Tag__DDZmq {
  height: 27px;
  border-radius: 20px;
  padding: 3px 26px 3px 24px;
  display: inline-block;
  margin-bottom: 50px; }
  .Footer_Tag__DDZmq p {
    margin-top: 4px;
    padding: 0px;
    font-family: Arial;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    color: #ffffff; }

.Footer_Footer__2h11U {
  text-align: left;
  padding-top: 70px;
  padding-bottom: 40px;
  display: block;
  background-color: #19191A;
  color: #ececec; }
  .Footer_Footer__2h11U .Footer_Tag__DDZmq {
    background-color: whitesmoke; }
    .Footer_Footer__2h11U .Footer_Tag__DDZmq p {
      color: #19191A; }

.Footer_FooterConent__1v9pK {
  width: 70%;
  max-width: 800px;
  margin: auto; }

.Footer_Social__2XbEY {
  bottom: 0;
  display: inline-block;
  width: 100%; }

.Footer_SocialLinks__nGYVe {
  display: flex;
  justify-content: space-between;
  width: 65%;
  float: left;
  opacity: 0.7; }
  .Footer_SocialLinks__nGYVe p {
    font-family: Merriweather;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.3px;
    color: whitesmoke; }

.Footer_Email__3SeeL {
  float: right;
  margin-top: -27px; }
  .Footer_Email__3SeeL .Footer_EmailSub__3LAJn {
    text-align: right;
    font-family: Merriweather;
    font-size: 15px;
    font-weight: bold;
    color: whitesmoke;
    opacity: 0.5; }
  .Footer_Email__3SeeL .Footer_ActualEmail__3Ltzm {
    text-align: right;
    font-family: Merriweather;
    font-size: 19px;
    font-weight: 800;
    color: whitesmoke;
    letter-spacing: 0.3px;
    margin-top: -9px; }

@media only screen and (max-width: 880px) {
  .Footer_SocialLinks__nGYVe {
    width: 100%;
    flex-direction: column; }
  .Footer_Email__3SeeL {
    margin-top: 20px;
    float: left; }
    .Footer_Email__3SeeL .Footer_EmailSub__3LAJn {
      text-align: left; }
    .Footer_Email__3SeeL .Footer_ActualEmail__3Ltzm {
      text-align: left; } }

.NavHeader_HeaderBlock__17oxn {
  color: #2c2c2c;
  background-color: #fefefe;
  font-size: 18px;
  display: block;
  border-bottom: solid 2px whitesmoke; }

.NavHeader_HeaderBlockContent__3Snap {
  width: 70%;
  font-family: Inconsolata;
  max-width: 800px;
  margin: auto;
  padding-top: 30px;
  padding-bottom: 20px; }

.NavHeader_BackNav__sSyN6 {
  position: absolute;
  -webkit-transition: all 0.6s cubic-bezier(0, 0.5, 0.51, 1);
  transition: all 0.6s cubic-bezier(0, 0.5, 0.51, 1); }
  .NavHeader_BackNav__sSyN6 img {
    float: left;
    height: 19px;
    width: 12px; }
  .NavHeader_BackNav__sSyN6 h3 {
    float: right;
    color: #303030;
    font-family: Inconsolata;
    font-size: 19px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-top: 0px;
    margin-left: 12px; }
  .NavHeader_BackNav__sSyN6:hover {
    -webkit-transform: translate(-7px);
            transform: translate(-7px); }

.NavHeader_PageTitle__3fsRk {
  margin-top: -10px;
  font-weight: 700; }

.NavHeader_PageLinkContainer__1TtmI {
  font-size: 20px;
  font-weight: 700;
  color: #303030; }
  .NavHeader_PageLinkContainer__1TtmI p {
    -webkit-transition: all 0.6s cubic-bezier(0, 0.5, 0.51, 1);
    transition: all 0.6s cubic-bezier(0, 0.5, 0.51, 1);
    cursor: pointer; }
  .NavHeader_PageLinkContainer__1TtmI:hover p {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px); }

.NavHeader_NavHeader__eU76_ {
  width: 100%;
  display: flex;
  justify-content: space-evenly; }

