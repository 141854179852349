$text-color: #2c2c2c;
$secondary-background-color: rgb(245, 245, 245);
$footer-background-color: #19191A;
$footer-text: #ececec;

.a:hover, a:visited, a:link, a:active {
  text-decoration: none;
}

.Tag {
  height: 27px;
  border-radius: 20px;
  padding: 3px 26px 3px 24px;
  display: inline-block;
  margin-bottom: 50px;

  p {
    margin-top: 4px;
    padding: 0px;
    font-family: Arial;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    color: #ffffff;
  }
}

.Footer {
  .Tag {
    background-color: $secondary-background-color;
    p {
      color: $footer-background-color;
    }
  }
  text-align: left;
  padding-top: 70px;
  padding-bottom: 40px;
  display: block;
  background-color: $footer-background-color;
  color: $footer-text;
}

.FooterConent {
  width: 70%;
  max-width: 800px;
  margin: auto;
}

.Social {
  bottom: 0;
  display: inline-block;
  width: 100%;
}

.SocialLinks {
  display: flex;
  justify-content: space-between;
  width: 65%;
  float: left;
  opacity: 0.7;
  p {
    font-family: Merriweather;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.3px;
    color: $secondary-background-color;
  }
}

.Email {
  float: right;
  margin-top: -27px;
  .EmailSub {
    text-align: right;
    font-family: Merriweather;
    font-size: 15px;
    font-weight: bold;
    color: $secondary-background-color;
    opacity: 0.5;
  }
  .ActualEmail {
    text-align: right;
    font-family: Merriweather;
    font-size: 19px;
    font-weight: 800;
    color: $secondary-background-color;
    letter-spacing: 0.3px;
    margin-top: -9px;
  }
}

@media only screen and (max-width: 880px) {
  .SocialLinks {
    width: 100%;
    flex-direction: column;
  }
  .Email {
    margin-top: 20px;
    float: left;
    .EmailSub {
      text-align: left;
    }
    .ActualEmail {
      text-align: left;
    }
  }
}